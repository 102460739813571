.wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    width: 200px;
    height: 224px;
    background: url(https://static.cpa-test.cn/web-assets/image/data-empty.png) no-repeat 50% 0;
    background-size: 200px 200px;
  }
  .image2 {
    width: 96px;
    height: 96px;
    background: url(https://static.cpa-test.cn/web-assets/image/data-empty2.png) no-repeat 50% 0;
    background-size: 96px 96px;
  }
  .message {
    font-size: 14px;
    color: rgba($color: #000000, $alpha: 0.55);
  }
  .btn {
    width: 160px;
    height: 40px;
    background: #0276FE;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bolder;
    color: #ffffff;
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1em;
    user-select: none;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background-color: #026dea;
    }
  }
}
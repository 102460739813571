.wrapper {
  position: relative;
}
.form_item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .label {
    flex-shrink: 0;
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    width: 95px;
    box-sizing: border-box;
    padding-right: 12px;
    text-align: right;
    line-height: 1em;
    &::before {
      content: '* ';
      font-size: 14px;
      font-weight: bolder;
      color: #F5222D;
    }
    &.unrequired {
      &::before {
        content: '';
      }
    }
  }
  .label_empty {
    flex-shrink: 0;
    width: 95px;
  }
  .photo_wrapper {
    display: flex;
    align-items: center;
    .photo {
      width: 86px;
      height: 120px;
      box-sizing: border-box;
      border: solid 1px #f6f6f6;
      object-fit: cover;
      flex-shrink: 0;
      border-radius: 4px;
      margin-right: 15px;
    }
    .photo_tip {
      font-size: 12px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
    }
  }
  .input {
    flex: 1;
    height: 32px;
    background: rgba($color: #D9D9D9, $alpha: 0.15);
    border-radius: 4px;
    font-size: 14px;
    line-height: 1em;
    color: rgba($color: #0A0A0A, $alpha: 0.65);
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .tip {
    position: absolute;
    left: 95px;
    top: 34px;
    font-size: 12px;
    line-height: 1em;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    &::before {
      content: '* ';
      color: #F5222D;
    }
  }
}
.btn_tip_bar {
  display: flex;
  .icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    background: url( https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat;
    background-size: 100% 100%;
    margin: 3px 8px 0 0;
  }
  .btn_tip {
    font-size: 14px;
    line-height: 1.5em;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    .link {
      color: #0276FE;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.arrow {
        padding-right: 12px;
        background: url(https://static.cpa-test.cn/web-assets/image/arrow_blue.png) no-repeat 100% 50%;
        background-size: 12px 12px;
      }
    }
  }
}
.cutline {
  border-bottom: solid 1px #E9E9E9;
  margin-bottom: 24px;
}
.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .tabbar {
    display: flex;
    margin-bottom: 32px;
    border-bottom: solid 1px #E9E9E9;
    .tab {
      font-size: 16px;
      color: #000000;
      user-select: none;
      cursor: pointer;
      margin-right: 40px;
      padding-bottom: 10px;
      border-bottom: solid 1px transparent;
      &.active {
        color: #0276FE;
        font-weight: bolder;
        border-bottom: solid 1px #0276FE;
      }
    }
  }
  .btn_wrapper {
    padding-left: 96px;
  }
  .result_space {
    border-bottom: dashed 1px #E9E9E9;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  .btn_detail {
    font-size: 14px;
    color: #0276FE;
    user-select: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .detail_info {
    display: flex;
    .avatar {
      width: 116px;
      height: 160px;
      background-color: #eeeeee;
      margin-right: 24px;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .baseinfo {
      flex: 1;
      .row {
        display: flex;
        font-size: 18px;
        margin-bottom: 12px;
        label {
          width: 90px;
          flex-shrink: 0;
          color: rgba($color: #0A0A0A, $alpha: .65);
        }
        span {
          color: #0A0A0A;
          font-weight: bolder;
        }
      }
    }
  }
}
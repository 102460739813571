.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}
.btn_group {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: solid 1px #E9E9E9;
}
.table_wrapper {
  padding: 24px 32px;
  &.empty {
    padding: 0;
    height: calc(100% - 56px);
  }
}
.operator {
  display: flex;
  flex-direction: column;
  button {
    padding: 0 5px;
  }
  span {
    &:hover {
      text-decoration: underline;
    }
  }
}

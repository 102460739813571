.wrapper {
  position: relative;
}
.content_wrapper {
  position: relative;
}
.content_wrapper2 {
  position: relative;
  background-color: #F5F5F5;
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: stretch;
  .left_area {
    width: 208px;
    flex-shrink: 0;
    background-color: #ffffff;
  }
  .right_area {
    flex: 1;
    .right_content {
      position: relative;
      padding: 24px;
      min-height: calc(100vh - 160px);
      display: flex;
    }
  }
}

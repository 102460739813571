.wrapper {
  position: relative;
  overflow: hidden;
  .slide {
    background-color: #f6f6f6;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .btn_area {
    width: 64px;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
  }
  .btn {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &.next {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon_index_banner_next%402x.png);
      &:hover {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon_index_banner_next_%402x.png);
      }
    }
    &.prev {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon_index_banner_pre%402x.png);
      &:hover {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon_index_banner_pre_%402x.png);
      }
    }
  }
}
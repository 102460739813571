.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 2;
  &.align_top {
    justify-content: flex-start;
    padding: 80px 0;
  }
}
.panel {
  flex-shrink: 0;
  position: relative;
  width: 633px;
  padding: 0 0 24px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0,36,79,0.5);
  border-radius: 12px;
  overflow: hidden;
  .form_title {
    font-size: 20px;
    font-weight: bolder;
    color: #0276FE;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 80px;
    background: linear-gradient(225deg, #D3E7FF 0%, #F7FBFF 100%);
  }
  .content {
    padding: 0 80px;
  }
  .btn_close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: url(https://static.cpa-test.cn/web-assets/image/login-panel-close.png) no-repeat;
    background-size: 100% 100%;
  }
}
.wrapper {
  width: 368px; 
  height: 40px;
  :global(.btn_slide) {
    height: 40px !important;
    line-height: 40px !important;
    box-sizing: border-box;
  }
  :global(.btn_ok) {
    height: 40px !important;
    line-height: 40px !important;
    box-sizing: border-box;
  }
  :global(.nc_wrapper) {
    height: 40px !important;
    line-height: 40px !important;
  }
  :global(.nc_scale) {
    height: 40px !important;
  }
  :global(.nc-lang-cnt) {
    height: 40px !important;
    line-height: 40px !important;
  }
}
.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
  border-bottom: solid 1px #E9E9E9;
  background-color: #ffffff;
  .logo {
    width: 260px;
    height: 50px;
    background: url(https://static.cpa-test.cn/web-assets/image/cpa_logo2.png) no-repeat;
    background-size: 100% 100%;
    margin-left: 40px;
  }
  .menu_bar {
    display: flex;
    align-items: center;
    height: 50px;
    .menu {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 1em;
      font-weight: bolder;
      color: #0A0A0A;
      margin-right: 50px;
      user-select: none;
      cursor: pointer;
      &.drop {
        &::after {
          content: '';
          width: 16px;
          height: 16px;
          margin-left: 2px;
          background: url(https://static.cpa-test.cn/web-assets/image/icon_nav_open%402x.png) no-repeat;
          background-size: 16px 16px;
        }
      }
      &.drop:hover {
        &::after {
          background: url(https://static.cpa-test.cn/web-assets/image/icon_nav_close%402x.png) no-repeat;
          background-size: 16px 16px;
        }
        .child_menu {
          display: block;
        }
      }
      &.active {
        color: #0276FE;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .child_menu {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 200px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.15);
    padding: 8px 0;
    z-index: 9;
    border-radius: 6px;
    overflow: hidden;
    li {
      list-style: none;
      display: flex;
      // justify-content: center;
      padding-left: 24px;
      align-items: center;
      height: 48px;
      line-height: 1em;
      font-size: 20px;
      font-weight: normal;
      color: rgba($color: #0A0A0A, $alpha: 0.65);
      cursor: pointer;
      user-select: none;
      &.active {
        color: #0276FE;
        font-weight: bolder;
      }
      &:hover {
        color: #0276FE;
        background-color: rgba($color: #0276FE, $alpha: 0.05);
        .icon {
          &.testcenter {
            background-image: url(https://static.cpa-test.cn/web-assets/image/icon-testcenter_.png);
          }
          &.logout {
            background-image: url(https://static.cpa-test.cn/web-assets/image/icon-logout_.png);
          }
        }
      }
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &.testcenter {
          background-image: url(https://static.cpa-test.cn/web-assets/image/icon-testcenter.png);
        }
        &.logout {
          background-image: url(https://static.cpa-test.cn/web-assets/image/icon-logout.png);
        }
      }
    }
  }
  .btn_bar {
    display: flex;
    align-items: center;
    margin-right: 35px;
    .btn_login {
      width: 112px;
      height: 40px;
      background: #0276FE;
      box-shadow: 0px 6px 6px -4px rgba(2,118,254,0.7);
      border-radius: 4px;
      font-size: 16px;
      color: #ffffff;
      font-weight: bolder;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      user-select: none;
      cursor: pointer;
      margin-left: 8px;
      &.red {
        background: #EE1111;
        box-shadow: 0px 6px 6px -4px rgba(238,17,17,0.7);
      }
    }
    .telphone {
      // background: url(https://static.cpa-test.cn/web-assets/image/icon-telphone.png) no-repeat 0 3px;
      background-size: 16px 16px;
      // padding-left: 18px;
      font-size: 14px;
      font-weight: bolder;
      color: #0276FE;
      margin-right: 16px;
      a {
        font-weight: normal;
        color: #0276FE;
        &:hover {
          color: #0276FE;
          text-decoration: underline;
        }
      }
    }
    .user_info {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 16px;
      height: 50px;
      .avator {
        width: 26px;
        height: 26px;
        background: url(https://static.cpa-test.cn/web-assets/image/img-user-avator.png) no-repeat;
        background-size: 100% 100%;
        margin-right: 7px;
      }
      .user_phone {
        font-size: 16px;
        color: rgba($color: #0A0A0A, $alpha: 0.8);
        user-select: none;
        cursor: pointer;
        // &:hover {
        //   color: #0276FE;
        //   text-decoration: underline;
        // }
      }
      .child_menu {
        left: -50px;
      }
      &:hover {
        .child_menu {
          display: block;
        }
      }
      .line {
        height: 16px;
        border-right: solid 1px #CECECE;
        margin: 0 16px;
      }
      .btn_logout {
        font-size: 16px;
        color: #0A0A0A;
        user-select: none;
        cursor: pointer;
        &:hover {
          color: #0276FE;
          text-decoration: underline;
        }
      }
    }
  }
}
.wrapper {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.stem_wrapper {
  padding: 24px 40px 40px 40px;
  background-color: #ffffff;
  flex-shrink: 0;
}
.ques_txt {
  position: relative;
  font-size: 16px;
  color: #0A0A0A;
  padding-left: 66px;
  user-select: none;
  .number {
    position: absolute;
    top: 0;
    left: 40px;
  }
  .text {
    white-space: pre-wrap;
    .rich_text {
      color: #0A0A0A;
      p {
        color: #0A0A0A;
      }
      table {
        border: solid 1px #cccccc;
        tr {
          border-bottom: solid 1px #cccccc;
          td {
            border-right: solid 1px #cccccc;
            padding: 6px;
          }
        }
      }
      img {
        max-width: 100% !important;
      }
    }
  }
  &.option {
    color: rgba($color: #0A0A0A, $alpha: 0.65);
    margin-bottom: 16px;
    cursor: pointer;
    &:hover {
      color: #0A0A0A;
    }
    &.active {
      font-size: 22px;
      color: #0276FE;
      .ques_image {
        img {
          border: solid 1px #0276FE;
        }
      }
      .btn_audio {
        top: 6px;
      }
    }
    &.disable {
      cursor: default;
    }
  }
}
.ques_image {
  display: flex;
  img {
    max-width: 400px;
    margin-right: 10px;
    box-sizing: border-box;
  }
}
.btn_audio {
  position: absolute;
  top: 1px;
  left: 0;
  width: 24px;
  height: 24px;
  background: url(https://static.cpa-test.cn/web-assets/image/btn_audio.png) no-repeat;
  background-size: 24px 24px;
  cursor: pointer;
  opacity: .6;
  transition: opacity .3s;
  &:hover {
    opacity: 1;
  }
}
.option_wrapper {
  position: relative;
  padding: 24px 40px 40px 40px;
  box-sizing: border-box;
  background-color: #F5F5F5;
  flex: 1;
  flex-shrink: 0;
  &.code {
    border-top: solid 1px #cccccc;
    padding: 0;
  }
  .code_wrapper {
    display: flex;
    .code_com {
      flex: 1;
      box-sizing: border-box;
      border-left: solid 1px #dddddd;
      .codetitle {
        display: flex;
        height: 34px;
        padding-left: 40px;
        align-items: center;
        background-color: #f5f5f5;
        font-size: 14px;
        font-weight: bolder;
      }
    }
  }
  @keyframes upanddown {
    0% { transform: translateX(-50%) translateY(0); }
    50% { transform: translateX(-50%) translateY(-6px); }
    100% { transform: translateX(-50%) translateY(0); }
  }
  .savetip {
    position: absolute;
    top: -30px;
    left: 200px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 1;
    transform: translateX(-50%);
    border: solid 1px #F5222D;
    font-size: 16px;
    color: #F5222D;
    font-weight: bolder;
    background-color: lightgoldenrodyellow;
    animation: upanddown 1.6s infinite;
    &::after {
      content: '';
      position: absolute;
      top: 30px;
      left: 50%;
      width: 20px;
      height: 15px;
      background: url(http://cpa-prod.oss-cn-beijing.aliyuncs.com/web-assets/image/1608644724431_bd71065d.png) no-repeat;
      background-size: 20px 15px;
      transform: rotate(90deg);
    }
  }
}
.frame_border {
  position: relative;
  height: calc(100vh - 160px);
  .save_tip {
    position: absolute;
    top: 10px;
    left: 245px;
    font-size: 18px;
    font-weight: bolder;
    color: #F5222D;
    display: inline-block;
    border-radius: 18px;
    padding: 0 10px;
    background-color: rgba($color: #ffffff, $alpha: 0.6);
  }
}
.review_wrapper {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
  font-weight: bolder;
  color: #0CA563;
  white-space: pre-wrap;
  .wrong {
    color: #F5222D;
  }
}
.run_code_wrapper {
  position: relative;
  background-color: #ffffff;
  height: 100%;
  box-sizing: border-box;
  padding: 24px 24px 24px 40px;
  overflow-x: hidden;
  overflow-y: auto;
  .btn_area {
    display: flex;
    margin-bottom: 16px;
  }
  .run_result {
    font-size: 14px;
    line-height: 1.5em;
    font-family: monospace;
    white-space: pre-wrap;
  }
  .success {
    color: #0CA563;
  }
  .error {
    color: #F5222D;
  }
}
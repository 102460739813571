.wrapper {
  position: relative;
  background: #ffffff;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
  overflow: hidden;
  display: flex;
  .leftside {
    position: relative;
    flex-shrink: 0;
    width: 328px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba($color: #0276FE, $alpha: 0.08);
    &.hide {
      width: 80px;
      background-color: #ffffff;
      .left_content {
        display: none;
      }
      .btn_shouqi {
        position: absolute;
        top: 24px;
        left: 24px;
      }
    }
    .btn_shouqi {
      width: 24px;
      height: 24px;
      background: url(https://static.cpa-test.cn/web-assets/image/btn_shouqi.png) no-repeat;
      background-size: 24px 24px;
      cursor: pointer;
    }
    .left_content {
      padding: 24px;
      .left_title {
        height: 28px;
        line-height: 28px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        span {
          flex: 1;
          font-size: 20px;
          color: #0A0A0A;
          font-weight: bolder;
        }
      }
    }
  }
  .rightside {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .modal_mock {
      position: absolute;
      top: 28px;
      right: 40px;
      width: 248px;
      height: 126px;
      background: url(https://static.cpa-test.cn/web-assets/image/modal-mock.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
.footer {
  height: 80px;
  box-sizing: border-box;
  flex-shrink: 0;
  box-sizing: border-box;
  border-top: solid 1px #E9E9E9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
  .question_count {
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
  }
}

.btn {
  width: 112px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bolder;
  line-height: 1em;
  user-select: none;
  cursor: pointer;
  color: #ffffff;
  background: #FF6E00;
  transition: all .3s;
  &:hover {
    filter: brightness(92%);
  }
  &.disable {
    opacity: .5;
    pointer-events: none;
  }
  &.outline {
    border: solid 1px #FF6E00;
    color: #FF6E00;
    background: #ffffff;
    &:hover {
      filter: none;
    }
  }
  &.ghost {
    background-color: rgba($color: #FF6E00, $alpha: 0.1);
    color: #FF6E00;
  }
  &.shadow {
    box-shadow: 0px 6px 6px -4px #FF7227;
  }
}
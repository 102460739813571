.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  .table_wrapper {
    padding: 24px 32px;
    &.empty {
      padding: 0;
      height: calc(100% - 56px);
    }
  }
  .check_info {
    font-size: 16px;
    p {
      margin-bottom: 10px;
    }
    .user_info {
      display: flex;
      margin: 30px 0;
      img {
        width: 125px;
        height: 160px;
        margin-right: 20px;
        object-fit: cover;
        background-color: #eeeeee;
      }
      .rightinfo {
        flex: 1;
        p {
          font-weight: bolder;
          margin-bottom: 8px;
        }
      }
    }
    .btnbar {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }
}
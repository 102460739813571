.wrapper {
  position: relative;
}
.title {
  font-size: 20px;
  color: #0A0A0A;
  font-weight: bolder;
  margin-bottom: 16px;
}
.type_title {
  font-size: 14px;
  color: rgba($color: #0A0A0A, $alpha: 0.65);
  margin-bottom: 8px;
}
.btn_box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .box {
    cursor: pointer;
  }
}
.box {
  display: block;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: solid 1px #EBF4FF;
  border-radius: 2px;
  margin: 0 4px 5px 0;
  font-size: 16px;
  line-height: 1em;
  color: rgba($color: #0A0A0A, $alpha: 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  &.current {
    border: solid 2px #0276FE !important;
  }
  &.filled {
    background-color: #0CA563;
    color: #ffffff;
    border: none;
  }
  &.check {
    background-color: #FF6E00;
    color: #ffffff;
    border: none;
  }
  &.wrong {
    background-color: #F5222D;
    color: #ffffff;
    border: none;
  }
}
.legend {
  height: 24px;
  display: flex;
  .label {
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.65);
    margin: 0 8px 0 24px;
  }
}
.wrapper {
  position: relative;
  .form_item {
    width: 432px;
  }
  .input_border {
    position: relative;
    display: flex;
    margin-bottom: 24px;
    .label {
      width: 100px;
      flex-shrink: 0;
      font-size: 14px;
      text-align: right;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      margin: 5px 12px 0 0;
      &::before {
        content: '* ';
        color: #F5222D;
      }
      &.unrequired {
        &::before {
          content: '';
        }
      }
    }
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .error {
      position: absolute;
      left: 96px;
      top: 34px;
      line-height: 1em;
      font-size: 12px;
      color: #F5222D;
    }
  }
  .btn_group {
    display: flex;
    padding-left: 112px;
    margin-top: 16px;
  }
  .tip_text {
    width: 320px;
    box-sizing: border-box;
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    padding-left: 24px;
    line-height: 1.5em;
    background: url(https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat 0 3px;
    background-size: 16px 16px;
  }
}
.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  width: 100%;
  min-height: 100%;
}

.step_bar {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  .step {
    display: flex;
    align-items: center;
    .num {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border-radius: 16px;
      border: solid 1px rgba($color: #0A0A0A, $alpha: 0.1);
      font-size: 16px;
      line-height: 1em;
      color: rgba($color: #0A0A0A, $alpha: 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      i {
        font-style: normal;
      }
    }
    .name {
      font-size: 16px;
      color: rgba($color: #0A0A0A, $alpha: 0.25);
    }
    &.active {
      .num {
        background-color: #0276FE;
        border: none;
        color: #ffffff;
      }
      .name {
        color: #0A0A0A;
        font-weight: bolder;
      }
    }
    &.complete {
      .num {
        border: none;
        background: url(https://static.cpa-test.cn/web-assets/image/icon-step-complete.png) no-repeat;
        background-size: 100% 100%;
        i {
          display: none;
        }
      }
    }
  }
  .line {
    width: 190px;
    border-bottom: solid 1px rgba($color: #0A0A0A, $alpha: 0.1);
    margin: 0 16px;
    &.active {
      border-bottom: solid 1px #1890FF;
    }
  }
}

.form_wrapper {
  width: 415px;
  .form_item {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .label {
      flex-shrink: 0;
      font-size: 14px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      width: 95px;
      box-sizing: border-box;
      padding-right: 12px;
      text-align: right;
      line-height: 1em;
      &::before {
        content: '* ';
        font-size: 14px;
        font-weight: bolder;
        color: #F5222D;
      }
      &.unrequired {
        &::before {
          content: '';
        }
      }
    }
    .label_empty {
      flex-shrink: 0;
      width: 95px;
    }
    .photo_wrapper {
      display: flex;
      align-items: center;
      .photo {
        width: 86px;
        height: 120px;
        box-sizing: border-box;
        border: solid 1px #f6f6f6;
        object-fit: cover;
        flex-shrink: 0;
        border-radius: 4px;
        margin-right: 15px;
      }
      .photo_tip {
        font-size: 12px;
        color: rgba($color: #0A0A0A, $alpha: 0.55);
      }
    }
    .input {
      flex: 1;
      height: 32px;
      background: rgba($color: #D9D9D9, $alpha: 0.15);
      border-radius: 4px;
      font-size: 14px;
      line-height: 1em;
      color: rgba($color: #0A0A0A, $alpha: 0.65);
      display: flex;
      align-items: center;
      padding-left: 12px;
    }
    .tip {
      position: absolute;
      left: 95px;
      top: 34px;
      font-size: 12px;
      line-height: 1em;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      &::before {
        content: '* ';
        color: #F5222D;
      }
    }
  }
  .btn_next {
    flex: 1;
    flex-shrink: 0;
    height: 40px;
    background: #0276FE;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bolder;
    line-height: 1em;
    color: #ffffff;
    user-select: none;
    cursor: pointer;
    transition: all .3s;
    &.disable {
      pointer-events: none;
      opacity: .6;
    }
    &:hover {
      background: #026dea;
    }
  }
  .btn_tip_bar {
    display: flex;
    .icon {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      background: url( https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat;
      background-size: 100% 100%;
      margin: 3px 8px 0 0;
    }
    .btn_tip {
      font-size: 14px;
      line-height: 1.5em;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      .link {
        color: #0276FE;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        &.arrow {
          padding-right: 12px;
          background: url(https://static.cpa-test.cn/web-assets/image/arrow_blue.png) no-repeat 100% 50%;
          background-size: 12px 12px;
        }
      }
    }
  }
  .cutline {
    border-bottom: solid 1px #E9E9E9;
    margin-bottom: 24px;
  }
}

.success_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 24px;
  font-weight: bolder;
  color: #0A0A0A;
  line-height: 1em;
  margin-bottom: 50px;
  &::before {
    content: '';
    width: 44px;
    height: 44px;
    background: url(https://static.cpa-test.cn/web-assets/image/iocn-pay-success.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 16px;
  }
}
.order_info {
  display: flex;
  .left_side {
    flex: 1;
  }
  .right_side {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  margin-bottom: 40px;
  .row {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    .label {
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      &.required {
        &::before {
          content: '* ';
          color: red;
        }
      }
    }
    .value {
      color: rgba($color: #0A0A0A, $alpha: 0.8);
      flex: 1;
    }
    .price {
      line-height: 1em;
      font-size: 28px;
      font-weight: bolder;
      color: #F5222D;
    }
  }
  .disable {
    pointer-events: none;
    opacity: .6;
  }
  .pay_type {
    position: relative;
    width: 240px;
    height: 40px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba($color: #0276FE, $alpha: 0.5);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 14px;
    line-height: 1em;
    color: rgba($color: #0A0A0A, $alpha: 0.8);
    user-select: none;
    cursor: pointer;
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
    }
    &.wechat {
      &::before {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-pay-wechat.png);
      }
    }
    &.zfb {
      &::before {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-pay-zfb.png);
      }
    }
    &.active {
      border-color: #0276FE;
      &::after {
        content: '';
        position: absolute;
        right: -1px;
        bottom: -1px;
        width: 16px;
        height: 16px;
        background: url(https://static.cpa-test.cn/web-assets/image/icon-paytype-select.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .qrcode {
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    margin-right: 15px;
  }
  .frame_border {
    width: 216px;
    height: 216px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
  }
  iframe {
    display: block;
    width: 210px;
    height: 210px;
  }
}
.cross_level_tip {
  font-size: 12px;
  color: rgba($color: #0A0A0A, $alpha: 0.55);
  strong {
    color: #F5222D;
  }
}
.uploader {
  position: relative;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  border: solid 1px #D9D9D9;
  flex-shrink: 0;
  &:hover {
    border-color: #0276FE;
  }
  &.dashed {
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: '+';
      color: #D9D9D9;
      font-size: 32px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}
.paysuccess_guide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .important_tip {
    margin: 20px 0;
    color: red;
    font-size: 16px;
  }
}
.wrapper {
  position: relative;
  .info_bar {
    box-sizing: border-box;
    padding: 40px 115px 16px 120px;
    // height: 246px;
    background-color: #F5F9FF;
    .info_tree {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      .item {
        margin-right: 80px;
        .title {
          font-size: 20px;
          font-weight: bolder;
          color: #0A0A0A;
          margin-bottom: 18px;
        }
        .link, a {
          font-size: 14px;
          color: #0A0A0A;
          margin-bottom: 6px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .text {
          font-size: 14px;
          color: rgba($color: #0A0A0A, $alpha: 0.55);
          margin-bottom: 6px;
          &.flex {
            display: flex;
          }
        }
        .qrcode {
          width: 72px;
          height: 72px;
          margin-right: 6px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .organization {
      font-size: 14px;
      text-align: center;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
    }
  }
  .copyrights {
    height: 80px;
    background-color: #0276FE;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .copyinfo {
      font-size: 12px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      a {
        opacity: .75;
      }
      .line {
        margin: 0 6px;
        opacity: .5;
      }
    }
    a {
      color: #ffffff;
      &:hover {
        text-decoration: underline;
        opacity: 1;
      }
      &.police {
        padding-left: 22px;
        background: url(../../assets/gawb.png) no-repeat;
      }
    }
  }
}
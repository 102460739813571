.wrapper {
  position: relative;
}
.advert_wrapper {
  padding: 40px;
}
.module_title {
  display: flex;
  padding: 0 40px;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  .line {
    flex: 1;
    border-top: solid 1px #E9E9E9;
  }
  .icon {
    width: 13px;
    height: 13px;
    background: url(https://static.cpa-test.cn/web-assets/image/shape_index_lingxing.png) no-repeat;
    background-size: 13px 13px;
  }
  .title {
    font-size: 38px;
    font-weight: bolder;
    color: #0A0A0A;
    margin: 0 20px;
  }
}
.organizations {
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  justify-content: space-between;
  margin-bottom: 100px;
  .org_item {
    width: 328px;
    height: 180px;
    margin-bottom: 16px;
    opacity: 0;
    cursor: pointer;
    transform: translateY(30px);
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
      transition: all .3s;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px rgba($color: #000000, $alpha: .1);
      }
    }
  }
  .org_space {
    width: 328px;
  }
}
.certificate {
  padding: 0 40px;
  margin-bottom: 120px;
  img {
    width: 100%;
  }
}
.test_scince {
  display: flex;
  justify-content: center;
  // padding: 0 40px;
  margin-bottom: 120px;
  .card {
    width: 440px;
    height: 338px;
    flex-shrink: 0;
    margin-right: 20px;
    opacity: 0;
    transform: translateY(30px);
    &:last-child {
      margin-right: 0;
    }
    .cover {
      width: 100%;
      margin-bottom: 24px;
    }
    .title {
      font-size: 30px;
      line-height: 1em;
      font-weight: bolder;
      color: #0A0A0A;
      padding: 0 24px;
      margin-bottom: 16px;
    }
    .summary {
      font-size: 14px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      padding: 0 24px;
    }
    .card_content {
      height: 100%;
      border-radius: 4px;
      box-sizing: border-box;
      border: solid 1px #EFEFEF;
      transition: all .3s;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px rgba($color: #000000, $alpha: .1);
      }
    }
  }
}
.exam_card {
  width: 100%;
  height: 176px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #E9E9E9;
  margin-bottom: 16px;
  background-color: #ffffff;
  &:last-child {
    margin-bottom: 0;
  }
  .title {
    height: 54px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border-bottom: solid 1px #E9E9E9;
    padding-left: 24px;
    font-size: 14px;
    font-weight: bolder;
    color: #0A0A0A;
  }
  .content {
    display: flex;
    padding: 20px 32px 0 32px;
    .leftarea {
      flex: 1;
    }
    .rightarea {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .info {
      font-size: 14px;
      color: #0A0A0A;
      margin-bottom: 8px;
      label {
        opacity: .55;
      }
    }
    .timer {
      font-size: 14px;
      color: #F5222D;
      margin-bottom: 8px;
      padding-left: 20px;
      background: url(https://static.cpa-test.cn/web-assets/image/icon_exam_timer.png) no-repeat 0 3px;
      background-size: 16px 16px;
    }
    .no_timer {
      font-size: 14px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      margin-bottom: 8px;
    }
    .btn_group {
      display: flex;
    }
  }
}
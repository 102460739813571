.payinfo {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .left_side {
    flex: 1;
  }
  .right_side {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .row {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 16px;
    .label {
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      &.required {
        &::before {
          content: '* ';
          color: red;
        }
      }
    }
    .value {
      color: rgba($color: #0A0A0A, $alpha: 0.8);
      flex: 1;
    }
    .price {
      line-height: 1em;
      font-size: 28px;
      font-weight: bolder;
      color: #F5222D;
    }
  }
  .disable {
    pointer-events: none;
    opacity: .6;
  }
  .pay_type {
    position: relative;
    width: 240px;
    height: 40px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid rgba($color: #0276FE, $alpha: 0.5);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 14px;
    line-height: 1em;
    color: rgba($color: #0A0A0A, $alpha: 0.8);
    user-select: none;
    cursor: pointer;
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
    }
    &.wechat {
      &::before {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-pay-wechat.png);
      }
    }
    &.zfb {
      &::before {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-pay-zfb.png);
      }
    }
    &.active {
      border-color: #0276FE;
      &::after {
        content: '';
        position: absolute;
        right: -1px;
        bottom: -1px;
        width: 16px;
        height: 16px;
        background: url(https://static.cpa-test.cn/web-assets/image/icon-paytype-select.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .qrcode {
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    margin-right: 15px;
  }
  .frame_border {
    width: 216px;
    height: 216px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
  }
  iframe {
    display: block;
    width: 210px;
    height: 210px;
  }
}
.success_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 24px;
  font-weight: bolder;
  color: #0A0A0A;
  line-height: 1em;
  margin-bottom: 30px;
  &::before {
    content: '';
    width: 44px;
    height: 44px;
    background: url(https://static.cpa-test.cn/web-assets/image/iocn-pay-success.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 16px;
  }
}
.btnbar {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.paysuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  .summary {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
.wrapper {
  position: relative;
  padding: 40px;
  h1 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 40px;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.8em;
  }
}
.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
  font-size: 18px;
  color: #999999;
  flex-direction: column;
  img {
    width: 200px;
  }
}
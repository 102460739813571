.page_bar {
  height: 56px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 32px;
  border-bottom: solid 1px #E9E9E9;
  .title {
    font-size: 16px;
    font-weight: bolder;
    color: #0A0A0A;
    flex: 1;
  }
}
.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.avatar {
  width: 80px;
  height: 112px;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.info {
  font-size: 12px;
  color: rgba($color: #0A0A0A, $alpha: 0.8);
  margin-bottom: 4px;
  label {
    color: rgba($color: #0A0A0A, $alpha: 0.55);
  }
}
.wrapper {
  position: relative;
  background: rgba($color: #0276FE, $alpha: 0.08);
  height: 100vh;
}
.content {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.empty {
    padding: 0;
    height: calc(100vh - 80px);
    background-color: #ffffff;
    justify-content: center;
  }
}
.empty_wrapper {
  display: flex;
  align-items: center;
  .image {
    width: 297px;
    height: 297px;
    background: url(https://static.cpa-test.cn/web-assets/image/data-empty.png) no-repeat;
    background-size: 100% 100%;
    margin-right: 87px;
  }
  .rightarea {
    p {
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      margin-bottom: 32px;
    }
    .title {
      color: #0A0A0A;
      font-size: 36px;
      font-weight: bolder;
      margin-bottom: 8px;
    }
  }
}
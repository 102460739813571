.wrapper_group {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  &.empty {
    height: 297px;
    .content_wrapper {
      padding: 0;
      height: 240px;
    }
  }
}
.content_wrapper {
  padding: 24px 32px;
}
.content_wrapper2 {
  display: flex;
  padding: 0 32px;
  height: 222px;
  justify-content: space-between;
}
.step_card {
  width: 240px;
  margin-top: 24px;
  .card_border {
    width: 100%;
    height: 96px;
    box-sizing: border-box;
    border: solid 1px #FF7227;
    background-color: rgba($color: #FF7227, $alpha: 0.04);
    border-radius: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    .step_num {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 40px;
      height: 24px;
      background: url(https://static.cpa-test.cn/web-assets/image/icon_guide_number.png) no-repeat;
      background-size: 100% 100%;
      font-size: 14px;
      font-weight: bolder;
      color: #ffffff;
      line-height: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Arial, Helvetica, sans-serif;
    }
    .title {
      font-size: 16px;
      font-weight: bolder;
      color: #FF7227;
      line-height: 1.3em;
      text-align: center;
    }
  }
  .summary {
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
  }
}
.next_icon {
  width: 24px;
  height: 24px;
  margin: 0 15px;
  background: url(https://static.cpa-test.cn/web-assets/image/icon_guide_arrow.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 61px;
}
.count_bar {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .message {
    flex: 1;
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.65);
    .count {
      color: #F5222D;
      margin: 0 3px;
    }
  }
  .btn_link {
    font-size: 14px;
    color: #0276FE;
    cursor: pointer;
    padding-right: 12px;
    background: url(https://static.cpa-test.cn/web-assets/image/arrow_blue.png) no-repeat 100% 50%;
    background-size: 12px 12px;
    &:hover {
      text-decoration: underline;
    }
  }
}
.toptip {
  width: 960px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #FFE58F;
  margin-bottom: 24px;
  padding: 9px 16px 9px 40px;
  background: #FFFBE6 url(https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat 18px 13px;
  background-size: 16px 16px;
  color: #0A0A0A;
}
.reason {
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 3px;
  background: url(https://static.cpa-test.cn/web-assets/image/icon_order-question.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  .popup {
    display: none;
    position: absolute;
    top: 26px;
    left: -20px;
    width: 200px;
    padding: 8px 16px;
    box-sizing: border-box;
    background: #FFF1F0;
    border: 1px solid #FFCCC7;
    border-radius: 4px;
    z-index: 3;
    font-size: 12px;
    color: #F5222D;
    &::after {
      content: '';
      position: absolute;
      top: -7px;
      left: 21px;
      width: 12px;
      height: 12px;
      box-sizing: border-box;
      background-color: #FFF1F0;
      transform-origin: center;
      transform: rotate(45deg);
      border-left: solid 1px #FFCCC7;
      border-top: solid 1px #FFCCC7;
    }
  }
  &:hover {
    .popup {
      display: block;
    }
  }
  &.toleft {
    .popup {
      left: auto;
      right: -20px;
      &::after {
        left: auto;
        right: 21px;
      }
    }
  }
}
.btn_smscode {
  flex-shrink: 0;
  width: 120px;
  height: 40px;
  background: #10DADB;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  font-size: 14px;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
  margin-left: 4px;
  &.disable {
    background-color: #CECECE;
    pointer-events: none;
  }
}
.btn_login {
  width: 368px;
  height: 40px;
  background: #0276FE;
  box-shadow: 0px 6px 6px -4px rgba(2,118,254,0.7);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  font-size: 16px;
  font-weight: bolder;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
  margin-bottom: 12px;
  transition: all .3s;
  &.disable {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    background-color: #026dea;
  }
}
.row {
  display: flex;
  align-items: center;
  .space {
    width: 105px;
  }
}
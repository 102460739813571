.wrapper {
  position: relative;
  width: 580px;
  height: 822px;
  background-color: #FFFFFF;
  background-image: url(https://static.cpa-test.cn/web-assets/image/enter_card_bg.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.14);
  background-size: 566px 808px;
  box-sizing: border-box;
  padding: 44px 56px 0 56px;
  display: flex;
  flex-direction: column;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  h1 {
    font-size: 22px;
    color: #0A0A0A;
    margin-bottom: 4px;
    text-align: center;
  }
  h2 {
    font-size: 36px;
    color: #0A0A0A;
    margin-bottom: 40px;
    text-align: center;
  }
  .userinfo {
    display: flex;
    margin-bottom: 60px;
    .photo {
      width: 159px;
      height: 220px;
      box-sizing: border-box;
      background-color: #F5F5F5;
      border-radius: 4px;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: auto;
        height: 100%;
      }
    }
    .otherinfo {
      .row {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bolder;
        color: #0A0A0A;
        line-height: 1.5em;
        margin-bottom: 16px;
        label {
          position: relative;
          opacity: .65;
          width: 90px;
          font-weight: normal;
          flex-shrink: 0;
          text-align: justify;
          text-align-last: justify;
          box-sizing: border-box;
          padding-right: 16px;
          &::after {
            content: ':';
            position: absolute;
            top: -2px;
            right: 8px;
          }
        }
      }
    }
  }
  .notice_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .squre {
      width: 7px;
      height: 7px;
      background: url(https://static.cpa-test.cn/web-assets/image/shape_index_lingxing.png) no-repeat;
      background-size: 100% 100%;
    }
    .line {
      width: 160px;
      border-bottom: solid 1px rgba(0,0,0,0.2);
    }
    .title {
      font-size: 20px;
      font-weight: bolder;
      color: #0A0A0A;
    }
  }
  .notice_content {
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    .item {
      position: relative;
      padding-left: 16px;
      .num {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
.btn_wraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.tip {
  font-size: 14px;
  padding-left: 20px;
  color: rgba($color: #0A0A0A, $alpha: 0.55);
  background: url(https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat 0 50%;
  background-size: 16px 16px;
  margin-top: 12px;
}
.wrapper_group {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 24px;
}
.content_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}
.btn_group {
  display: flex;
  padding-left: 112px;
  margin-top: 16px;
}
.logo {
  width: 260px;
  height: 50px;
  background: url(https://static.cpa-test.cn/web-assets/image/cpa_logo2.png) no-repeat;
  background-size: 100% 100%;
  margin-bottom: 50px;
}
.form_item {
  width: 432px;
}
.wrapper {
  position: relative;
}
.content {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .notice {
    position: relative;
    max-width: 1021px;
    h1 {
      text-align: center;
      font-size: 24px;
      font-weight: bolder;
      color: #0A0A0A;
      margin-bottom: 24px;
    }
    p {
      position: relative;
      padding-left: 16px;
      font-size: 14px;
      line-height: 1.8em;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      margin-bottom: 20px;
      span {
        position: absolute;
        top: 0;
        left: 0;
      }
      &.bottom {
        position: relative;
        text-align: right;
        margin-top: 64px;
      }
    }
    .modal {
      position: absolute;
      bottom: -12px;
      right: 65px;
      width: 100px;
      height: 100px;
      background: url(https://static.cpa-test.cn/web-assets/image/exam-modal.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
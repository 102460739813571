.wrapper {
  position: relative;
}
.video {
  width: 320px;
  height: 240px;
  border-radius: 4px;
  background-color: #D8D8D8;
}
.step_bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  .step {
    position: relative;
    display: flex;
    align-items: center;
    .num {
      width: 32px;
      height: 32px;
      box-sizing: border-box;
      border-radius: 16px;
      border: solid 1px rgba($color: #0A0A0A, $alpha: 0.1);
      font-size: 16px;
      line-height: 1em;
      color: rgba($color: #0A0A0A, $alpha: 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      i {
        font-style: normal;
      }
    }
    .name {
      font-size: 16px;
      color: rgba($color: #0A0A0A, $alpha: 0.25);
    }
    .summary {
      position: absolute;
      top: 32px;
      left: 40px;
      font-size: 12px;
      width: 200px;
      color: rgba($color: #0A0A0A, $alpha: 0.25);
    }
    &.active {
      .num {
        background-color: #0276FE;
        border: none;
        color: #ffffff;
      }
      .name {
        color: #0A0A0A;
        font-weight: bolder;
      }
      .summary {
        color: rgba($color: #0A0A0A, $alpha: 0.55);
      }
    }
    &.complete {
      .num {
        border: none;
        background: url(https://static.cpa-test.cn/web-assets/image/icon-step-complete.png) no-repeat;
        background-size: 100% 100%;
        i {
          display: none;
        }
      }
    }
  }
  .line {
    width: 190px;
    border-bottom: solid 1px rgba($color: #0A0A0A, $alpha: 0.1);
    margin: 0 16px;
    &.active {
      border-bottom: solid 1px #1890FF;
    }
  }
}
.step_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.step_wrapper2 {
  display: flex;
  margin-top: 64px;
  .right_area {
    margin-left: 80px;
  }
  .title {
    font-size: 20px;
    font-weight: bolder;
    color: #0A0A0A;
    margin-bottom: 16px;
  }
  .second_title {
    font-size: 14px;
    font-weight: bolder;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    margin-bottom: 8px;
  }
  .notice {
    width: 622px;
    margin: 24px 0 40px 0;
    box-sizing: border-box;
    &.brightness {
      background: rgba(250,173,20,0.05);
      border-radius: 8px;
      border: 3px solid #FAAD14;
      padding: 16px;
    }
    p {
      position: relative;
      padding-left: 15px;
      font-size: 14px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .btn_link {
    display: inline-block;
    font-size: 14px;
    color: #0276FE;
    cursor: pointer;
    padding-right: 12px;
    background: url(https://static.cpa-test.cn/web-assets/image/arrow_blue.png) no-repeat 100% 50%;
    background-size: 12px 12px;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn_group {
    display: flex;
  }
}
.check_title {
  margin-top: 140px;
  padding-left: 60px;
  height: 44px;
  line-height: 44px;
  font-size: 24px;
  font-weight: bolder;
  color: #0A0A0A;
  margin-bottom: 74px;
  background-repeat: no-repeat;
  background-size: 44px 44px;
  background-image: url(https://static.cpa-test.cn/web-assets/image/iocn-pay-success.png);
  &.error {
    color: #F5222D;
    background-image: url(https://static.cpa-test.cn/web-assets/image/icon_check_error.png);
  }
}
.browser_group {
  display: flex;
  width: 180px;
  justify-content: space-between;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.browser {
  width: 128px;
  height: 128px;
  margin-bottom: 32px;
}
.screen_check {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .state_img {
    width: 366px;
    height: 160px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(https://static.cpa-test.cn/web-assets/image/screen_share_success.png);
    &.error {
      background-image: url(https://static.cpa-test.cn/web-assets/image/screen_share_error.png);
    }
  }
}
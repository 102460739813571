.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}
.content_area {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form_row {
  width: 936px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form_item {
  width: 417px;
}
.input_border {
  position: relative;
  display: flex;
  align-items: center;
  .label {
    width: 85px;
    flex-shrink: 0;
    font-size: 14px;
    text-align: right;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    margin-right: 12px;
    &::before {
      content: '* ';
      color: #F5222D;
    }
    &.unrequired {
      &::before {
        content: '';
      }
    }
  }
  .content {
    flex: 1;
    display: flex;
  }
  .error {
    position: absolute;
    left: 96px;
    top: 34px;
    line-height: 1em;
    font-size: 12px;
    color: #F5222D;
  }
}
.tip_text {
  font-size: 12px;
  color: rgba($color: #0A0A0A, $alpha: 0.55);
  padding-left: 24px;
  line-height: 16px;
  background: url(https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat;
  background-size: 16px 16px;
  margin-bottom: 24px;
}
.line {
  width: 100%;
  border-bottom: solid 1px #E9E9E9;
  margin-bottom: 24px;
}
.avator {
  position: relative;
  width: 108px;
  height: 150px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  border: solid 1px #f1f1f1;
  flex-shrink: 0;
  transition: all .3s;
  &:hover {
    border-color: #0276FE;
  }
  &.dashed {
    border-style: dashed;
    border-width: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: '+';
      color: #D9D9D9;
      font-size: 30px;
      transition: all .3s;
    }
    &:hover {
      &::after {
        color: #0276FE;
      }
    }
  }
  &.demo {
    &:hover {
      border-color: #f1f1f1;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .mask_loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #0A0A0A, $alpha: 0.36);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.avator_tip {
  height: 150px;
  box-sizing: border-box;
  padding: 12px 16px;
  flex: 1;
  background-color: rgba($color: #E9E9E9, $alpha: 0.3);
  border-radius: 4px;
  display: flex;
  .area {
    flex: 1;
    .main {
      height: 22px;
      font-size: 14px;
      font-weight: bolder;
      color: #0A0A0A;
      margin-bottom: 8px;
    }
    .summary {
      position: relative;
      font-size: 12px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      padding-left: 8px;
      &::before {
        content: '*';
        color: #F5222D;
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
  }
}
.btn_submit {
  width: 320px;
  height: 40px;
  background: #0276FE;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bolder;
  color: #ffffff;
  margin-top: 40px;
  transition: all .3s;
  &.disable {
    opacity: .5;
    pointer-events: none;
  }
  &:hover {
    background-color: #026dea;
  }
}
.input_row {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  &.no_margin {
    margin-bottom: 0;
  }
}

.label {
  flex-shrink: 0;
  font-size: 16px;
  text-align: right;
  color: rgba($color: #0A0A0A, $alpha: 0.55);
  margin-right: 12px;
  &::before {
    content: '* ';
    color: #F5222D;
    font-weight: bolder;
  }
  &.unrequired {
    &::before {
      content: '';
    }
  }
}
.mini {
  .label {
    font-size: 14px;
  }
}

.input_border {
  position: relative;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  transition: all .3s;
  &.focus {
    border-color: #0276FE;
  }
  &.error {
    border-color: #F5222D;
  }
  .icon {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    &.phone {
      margin-left: 12px;
      background-image: url(https://static.cpa-test.cn/web-assets/image/login-icon-phone.png);
    }
    &.pwd {
      margin-left: 12px;
      background-image: url(https://static.cpa-test.cn/web-assets/image/login-icon-pwd.png);
    }
    &.smscode {
      margin-left: 12px;
      background-image: url(https://static.cpa-test.cn/web-assets/image/login-icon-smscode.png);
    }
    &.seepwd {
      margin-right: 12px;
      cursor: pointer;
      background-image: url(https://static.cpa-test.cn/web-assets/image/login-icon-seepwd.png);
    }
    &.hidepwd {
      margin-right: 12px;
      cursor: pointer;
      background-image: url(https://static.cpa-test.cn/web-assets/image/login-icon-seepwd_.png);
    }
  }
  input {
    flex: 1;
    height: 100%;
    border: none;
    background: none;
    font-size: 16px;
    color: #0A0A0A;
    outline: none;
    margin: 0 12px;
    &.hasicon {
      margin-left: 8px;
    }
    &::placeholder {
      color: rgba($color: #0A0A0A, $alpha: 0.25);
    }
  }
  .error {
    position: absolute;
    left: 0;
    top: 42px;
    line-height: 1em;
    font-size: 12px;
    color: #F5222D;
  }
  .tip {
    position: absolute;
    left: 0;
    top: 42px;
    line-height: 1em;
    font-size: 12px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    &::before {
      content: '* ';
      color: #F5222D;
    }
  }
}
.mini {
  .label {
    font-size: 14px;
  }
  .input_border {
    height: 32px;
    input {
      font-size: 14px;
    }
    .error, .tip {
      top: 34px;
    }
  }
}
.disable {
  .input_border {
    border: none;
    background-color: rgba($color: #D9D9D9, $alpha: 0.15);
    input {
      color: rgba($color: #0A0A0A, $alpha: 0.65);
    }
  }
}
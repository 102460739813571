.wrapper {
  position: relative;
  max-width: 1060px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 60px 30px;
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  h2 {
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 30px;
    line-height: 2em;
    font-size: 16px;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
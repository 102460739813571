body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-message-notice-content {
  background: none !important;
  padding: 0 !important;
  box-shadow: none !important;
  position: relative;
  top: 63px;
}
.ant-message-custom-content {
  border-radius: 4px !important;
  padding: 8px 16px;
}

.ant-modal-confirm-body .anticon-info-circle {
  display: none;
}
.anticon-exclamation-circle {
  width: 0;
  height: 0;
  opacity: 0;
}
.ant-modal-confirm-title {
  font-size: 16px !important;
  font-weight: bolder;
  background: url(https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat 0 3px;
  background-size: 16px 16px;
  padding-left: 22px;
}
.ant-modal-confirm-content {
  white-space: pre-wrap;
  opacity: .65;
}

.ant-message-error {
  background-color: #FFF1F0 !important;
  color: #F5222D !important;
  border: solid 1px #FFCCC7;
}
.ant-message-success {
  background-color: #EBF9E3 !important;
  border: solid 1px #73bf40;
}
.ant-message-info {
  background-color: #ffffff !important;
  border: solid 1px #4091f7;
}

.ant-modal-confirm-confirm {
  margin-top: -85px !important;
  top: 50% !important;
}
.ant-btn-primary {
  background-color: #0276fe !important;
}
.ant-btn-primary:hover {
  background-color: #026dea !important;
}
.wrapper {
  position: relative;
  background: rgba($color: #0276FE, $alpha: 0.08);
  height: 100vh;
}
.content {
  width: 100%;
  box-sizing: border-box;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 24px;
  color: #0A0A0A;
  margin-bottom: 40px;
}
.subject_wrapper {
  display: flex;
  .card {
    width: 358px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #ffffff;
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
    .cover {
      height: 180px;
      background-color: #eeeeee;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .level_list {
      padding: 24px;
      .level {
        height: 40px;
        background: rgba($color: #0276FE, $alpha: 0.05);
        border-radius: 4px;
        margin-bottom: 16px;
        font-size: 16px;
        color: #0276FE;
        text-align: center;
        line-height: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        transition: all .3s;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #ffffff;
          background: #0276FE;
          box-shadow: 0px 6px 8px -4px rgba(2,118,254,0.7);
        }
      }
    }
  }
}
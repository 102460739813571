.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}
.content_area {
  padding: 24px 32px;
  &.empty {
    padding: 0;
    height: calc(100% - 56px);
  }
}
.user_list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  .user {
    width: calc(50% - 16px);
    height: 152px;
    box-sizing: border-box;
    padding: 16px 24px;
    background: rgba($color: #E9E9E9, $alpha: 0.3);
    border-radius: 4px;
    margin: 0 16px 16px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s;
    .avator {
      display: block;
      width: 86px;
      height: 120px;
      background-color: #ffffff;
      object-fit: cover;
      margin-right: 16px
    }
    .label {
      font-size: 16px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      transition: all .3s;
    }
    .username {
      font-size: 24px;
      font-weight: bolder;
      color: #0A0A0A;
      transition: all .3s;
    }
    &:hover {
      background: rgba(2,118,254,0.1);
      .label {
        color: rgba($color: #0276FE, $alpha: 0.55);
      }
      .username {
        color: #0276FE;
      }
    }
  }
}
.btn_add {
  width: 160px;
  height: 40px;
  background: #0276FE;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  font-size: 16px;
  font-weight: bolder;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
  transition: all .3s;
  &:hover {
    background-color: #026dea;
  }
}
.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
}
.query_bar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label {
    font-size: 14px;
    color: #0A0A0A;
    margin-right: 16px;
  }
}
.table_wrapper {
  padding: 24px 32px;
  &.empty {
    padding: 0;
    height: calc(100% - 56px);
  }
}
.modal_wrapper {
  display: flex;
  .side_area {
    flex: 1;
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      font-size: 14px;
      color: #0A0A0A;
      .label {
        width: 70px;
        flex-shrink: 0;
        opacity: .65;
      }
      .price {
        font-size: 28px;
        font-weight: bolder;
        color: #F5222D;
      }
    }
    .qrcode {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #EFEFEF;
    }
    iframe {
      display: block;
      width: 210px;
      height: 210px;
      margin: 0 auto;
    }
  }
}
.paytype_title {
  font-size: 14px;
  color: rgba($color: #0A0A0A, $alpha: 0.65);
  margin-bottom: 16px;
}
.pay_type {
  position: relative;
  // width: 240px;
  height: 40px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid rgba($color: #0276FE, $alpha: 0.5);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 14px;
  line-height: 1em;
  color: rgba($color: #0A0A0A, $alpha: 0.8);
  user-select: none;
  cursor: pointer;
  &::before {
    content: '';
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-right: 8px;
  }
  &.wechat {
    &::before {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-pay-wechat.png);
    }
  }
  &.zfb {
    &::before {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-pay-zfb.png);
    }
  }
  &.active {
    border-color: #0276FE;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      background: url(https://static.cpa-test.cn/web-assets/image/icon-paytype-select.png) no-repeat;
      background-size: 100% 100%;
    }
  }
}
.operator {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  button {
    padding: 0 5px;
  }
  span {
    &:hover {
      text-decoration: underline;
    }
  }
}
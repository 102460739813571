.btn {
  width: 100%;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bolder;
  line-height: 1em;
  user-select: none;
  cursor: pointer;
  color: #ffffff;
  background: #0276FE;
  transition: all .3s;
  &:hover {
    filter: brightness(92%);
  }
  &.green {
    background: #52C41A;
  }
  &.disable {
    background: #CECECE;
    pointer-events: none;
  }
  &.outline {
    border: solid 1px #0276FE;
    color: #0276FE;
    background: #ffffff;
    &:hover {
      filter: none;
    }
  }
  &.outgrayline {
    font-weight: normal;
    border: solid 1px #CECECE;
    color: rgba($color: #0A0A0A, $alpha: 0.8);
    background: #ffffff;
    &:hover {
      filter: none;
    }
  }
}
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.panel {
  position: relative;
  width: 1000px;
  height: 466px;
  box-sizing: border-box;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  .image {
    flex-shrink: 0;
    width: 472px;
    height: 100%;
    background: url(https://static.cpa-test.cn/web-assets/image/exam_login_image.png) no-repeat;
    background-size: 100% 100%;
  }
  .form_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 20px;
    color: #0A0A0A;
    margin-bottom: 16px;
  }
}
.logo {
  width: 260px;
  height: 50px;
  background: url(https://static.cpa-test.cn/web-assets/image/cpa_logo2.png) no-repeat;
  background-size: 100% 100%;
  margin-bottom: 24px;
}
.btn_login {
  width: 368px;
  height: 40px;
  background: #0276FE;
  box-shadow: 0px 6px 6px -4px rgba(2,118,254,0.7);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  font-size: 16px;
  font-weight: bolder;
  color: #ffffff;
  user-select: none;
  cursor: pointer;
  margin: 40px 0 12px 0 ;
  transition: all .3s;
  &.disable {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    background-color: #026dea;
  }
}
.remark {
  padding-left: 24px;
  background: url(https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat 0 3px;
  background-size: 16px 16px;
  font-size: 14px;
  color: rgba($color: #0A0A0A, $alpha: 0.8);
  .tip {
    display: block;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
  }
}
.btn_close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: url(https://static.cpa-test.cn/web-assets/image/login-panel-close.png) no-repeat;
  background-size: 100% 100%;
}
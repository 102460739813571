.wrapper {
  width: 140px;
  height: 40px;
  box-sizing: border-box;
  background: #FFFBE6;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-size: 16px;
  color: #F5222D;
  line-height: 1em;
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background: url(https://static.cpa-test.cn/web-assets/image/icon_exam_timer2.png) no-repeat;
    background-size: 16px 16px;
    margin-right: 6px;
  }
}
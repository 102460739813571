.wrapper {
  position: relative;
  margin-bottom: 32px;
}
.camera_video {
  display: block;
  width: 276px;
  height: 206px;
  background-color: #000000;
  margin-bottom: 4px;
}
.video_tip {
  font-size: 12px;
  color: rgba($color: #0A0A0A, $alpha: 0.55);
  a {
    color: #0276FE;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
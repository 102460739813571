.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  .image {
    width: 320px;
    height: 320px;
    margin-right: 80px;
    background: url(https://static.cpa-test.cn/web-assets/image/exam_complete.png) no-repeat;
    background-size: 100% 100%;
  }
  h1 {
    font-size: 36px;
    margin-bottom: 8px;
  }
  p {
    font-size: 16px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    margin-bottom: 32px;
  }
}

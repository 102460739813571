.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #ffffff, $alpha: 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapper {
  position: relative;
  width: 670px;
}
.main_title {
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
}
.photo_video {
  display: flex;
  margin-bottom: 40px;
  .sidearea {
    width: 335px;
    flex-shrink: 0;
    .small_title {
      font-size: 14px;
      font-weight: bolder;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      margin-bottom: 16px;
    }
    .video_area {
      width: 274px;
      height: 208px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FAFAFA;
      border-radius: 4px;
      img {
        width: 114px;
        height: 160px;
        object-fit: cover;
      }
      video {
        width: 214px;
        height: 160px;
        background-color: #D8D8D8;
      }
    }
  }
}
.btn_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  .profile {
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    margin-bottom: 8px;
  }
  .tip {
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    margin-top: 12px;
    width: 320px;
    &::before {
      content: '* ';
      color: #F5222D;
    }
  }
}
.notice_area {
  p {
    font-size: 14px;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    margin-bottom: 8px;
  }
}
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.6);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.panel {
  width: 580px;
  background-color: #ffffff;
  border-radius: 8px;
  .panel_name {
    height: 56px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px 0 24px;
    border-bottom: solid 1px #E9E9E9;
    .title {
      font-size: 16px;
      font-weight: bolder;
      color: #0A0A0A;
      flex: 1;
    }
    .btn_close {
      width: 32px;
      height: 32px;
      cursor: pointer;
      background: url(https://static.cpa-test.cn/web-assets/image/login-panel-close.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .content {
    padding: 30px 30px 10px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      width: 160px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        transform: scale(1.06);
        box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
      }
      img {
        width: 100%;
      }
    }
  }
}
.subjects_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 32px;
  .subject_card {
    width: 358px;
    height: 350px;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #E9E9E9;
    margin-right: 23px;
    .subject_cover {
      height: 180px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .subject_info {
      padding: 24px 16px;
    }
    .subject_name {
      font-size: 20px;
      font-weight: bolder;
      color: #0A0A0A;
      margin-bottom: 8px;
    }
    .subject_level {
      font-size: 14px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      margin-bottom: 30px;
    }
    .btn_enter {
      width: 326px;
      height: 40px;
      background: #0276FE;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      color: #ffffff;
      font-size: 16px;
      font-weight: bolder;
      user-select: none;
      cursor: pointer;
      transition: all .3s;
      &.disable {
        background-color: #CECECE;
        pointer-events: none;
      }
      &:hover {
        background-color: #026dea;
      }
    }
    &:hover {
      .subject_name, .subject_level {
        color: #0276FE;
      }
    }
  }
}
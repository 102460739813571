.wrapper {
  position: relative;
}
.input_border {
  position: relative;
  display: flex;
  .label {
    width: 85px;
    flex-shrink: 0;
    font-size: 14px;
    text-align: right;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
    margin: 5px 12px 0 0;
    &::before {
      content: '* ';
      color: #F5222D;
    }
    &.unrequired {
      &::before {
        content: '';
      }
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .error {
    position: absolute;
    left: 96px;
    top: 34px;
    line-height: 1em;
    font-size: 12px;
    color: #F5222D;
  }
}
.btn_group {
  display: flex;
  margin-top: 40px;
}
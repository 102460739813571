.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
}
.panel {
  position: relative;
  width: 528px;
  padding: 24px 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0,36,79,0.5);
  border-radius: 12px;
  &.big {
    width: 618px;
  }
  .content {
    padding: 0 80px;
    .logo {
      width: 260px;
      height: 50px;
      background: url(https://static.cpa-test.cn/web-assets/image/cpa_logo2.png) no-repeat;
      background-size: 100% 100%;
      margin: 0 auto 24px auto;
    }
    .form_title {
      position: relative;
      margin-bottom: 16px;
      .main {
        font-size: 20px;
        font-weight: bolder;
        color: #0A0A0A;
        margin-bottom: 4px;
      }
      .summary {
        font-size: 14px;
        color: rgba($color: #0A0A0A, $alpha: 0.55);
        span {
          color: #FAAD14;
        }
      }
    }
    .btn_smscode {
      flex-shrink: 0;
      width: 120px;
      height: 40px;
      background: #10DADB;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      font-size: 14px;
      color: #ffffff;
      user-select: none;
      cursor: pointer;
      margin-left: 4px;
      &.disable {
        background-color: #CECECE;
        pointer-events: none;
      }
    }
    .btn_login {
      width: 368px;
      height: 40px;
      background: #0276FE;
      box-shadow: 0px 6px 6px -4px rgba(2,118,254,0.7);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      font-size: 16px;
      font-weight: bolder;
      color: #ffffff;
      user-select: none;
      cursor: pointer;
      margin-bottom: 12px;
      transition: all .3s;
      &.disable {
        opacity: 0.5;
        pointer-events: none;
      }
      &:hover {
        background-color: #026dea;
      }
    }
    .profile_wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;
      color: rgba($color: #0A0A0A, $alpha: 0.55);
      margin-bottom: 24px;
      .profile {
        color: #0A0A0A;
        &:hover {
          color: #0276FE;
          text-decoration: underline;
        }
      }
    }
    .btn_group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 14px;
        color: #0276FE;
        user-select: none;
        cursor: pointer;
      }
    }
    .row {
      display: flex;
      align-items: center;
      .space {
        width: 92px;
      }
    }
  }
  .btn_close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: url(https://static.cpa-test.cn/web-assets/image/login-panel-close.png) no-repeat;
    background-size: 100% 100%;
  }
}
.wrapper {
  position: relative;
  background-color: #ffffff;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  margin-top: 32px;
  .query_bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .label {
      font-size: 14px;
      color: #0A0A0A;
      margin-right: 16px;
    }
  }
  .table_wrapper {
    padding: 24px 32px;
    &.empty {
      padding: 0;
      height: calc(100% - 56px);
    }
  }
  .operator {
    display: flex;
    flex-direction: column;
    button {
      padding: 0 5px;
    }
    span {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.toptip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 9px 16px 9px 40px;
  background: #FFFBE6 url(https://static.cpa-test.cn/web-assets/image/icon-text-tip.png) no-repeat 18px 13px;
  background-size: 16px 16px;
  color: #0A0A0A;
  &.inpanel {
    position: relative;
    width: 960px;
    border-radius: 4px;
    border: 1px solid #FFE58F;
    margin-bottom: 24px;
  }
}

.result_row {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bolder;
  color: #0A0A0A;
  margin-bottom: 16px;
  span {
    width: 90px;
    box-sizing: border-box;
    padding-right: 24px;
    font-size: 14px;
    text-align: right;
    color: rgba($color: #0A0A0A, $alpha: 0.55);
  }
}
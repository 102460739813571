.wrapper {
  position: relative;
}
.menu {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 26px;
  font-size: 14px;
  color: rgba($color: #0A0A0A, $alpha: 0.65);
  line-height: 1em;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: rgba($color: #0276FE, $alpha: 0.05);
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    &.guide {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-1.png);
    }
    &.signin {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-2.png);
    }
    &.order {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-3.png);
    }
    &.exam {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-4.png);
    }
    &.mock {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-5.png);
    }
    &.certifi {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-6.png);
    }
    &.users {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-7.png);
    }
    &.mycenter {
      background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-8.png);
    }
  }
  &.active {
    color: #0276FE;
    border-right: solid 2px #0276FE;
    background-color: rgba($color: #0276FE, $alpha: 0.1);
    .icon {
      &.guide {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-1_.png);
      }
      &.signin {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-2_.png);
      }
      &.order {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-3_.png);
      }
      &.exam {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-4_.png);
      }
      &.mock {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-5_.png);
      }
      &.certifi {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-6_.png);
      }
      &.users {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-7_.png);
      }
      &.mycenter {
        background-image: url(https://static.cpa-test.cn/web-assets/image/icon-leftmenu-8_.png);
      }
    }
  }
}
